import React from 'react';
import {Checkbox, FormControl, FormControlLabel, ListItem, Typography} from "@mui/material";
import List from "@mui/material/List";
import Div from "@jumbo/shared/Div";
import {useCalendarContext} from "./context";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {useAppMutation} from "../../../services";
import teacherServices from "../../../services/teacher-services";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";


const StyledCircle = styled('div')(({theme, color}) => ({
    backgroundColor: color,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
}));

const StyledSquare = styled('div')(({theme, color}) => ({
    backgroundColor: color,
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginRight: '5px',
}));

const Header = ({title, subheader, subheader1}) => {

    const {globalState, setGlobalState} = useCalendarContext();
    const {t} = useTranslation();

    const {
        mutate,
        isSuccess,
        isError,
        data
    } = useAppMutation(teacherServices.checkApproved);

    React.useEffect(() => {
        mutate()
    }, [])

    const handleButtonClick = () => {
        // Set global state to open the dialog
        setGlobalState({...globalState, openUnavailabilityForm: true});
    };

    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',

            }}
        >


            <Typography variant={"h2"}>{title}</Typography>
            {data && <JumboChipsGroup
                chips={[{label: t('calendar.approved')}]}
                mapKeys={{label: "name"}}
                spacing={1}
                size={"small"}
                defaultColor={"#008000"}
            />}

            {!data && <JumboChipsGroup
                chips={[{label: t('calendar.notApproved')}]}
                mapKeys={{label: "name"}}
                spacing={1}
                size={"small"}
                defaultColor={"#FF0000"}
            />}

            <div style={{alignItems: 'center'}}>
                <List
                    disablePadding
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: theme => theme.spacing(0, -2),
                        mb: 2,
                        mt: 2
                    }}
                >

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#4E0781",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.available-both')}
                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#f054b0",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.availableAppointment')}
                            </Typography>
                        </Div>
                    </ListItem>



                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#8C52FF",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.availableCourse')}
                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#FFB733",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.toBeEvaluated')}
                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#FF8933",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.evaluated')}
                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#59B2FF",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.readyBooking')}
                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#1D6ADE",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><Typography variant="body1" color="white">
                            {t('common.bookingCompleted')}
                        </Typography></Div>

                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#D22B2B",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t("common.cancelBtn")}                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#dedede",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}> <StyledCircle color="#D22B2B"/>

                            <Typography sx={{ml: 0.5}} variant="body1" color="white">
                                {t('common.notAttended')}
                            </Typography>

                        </Div>
                    </ListItem>


                </List>
                <Div sx={{display: 'flex'}}>
                    <FormControl sx={{mr: 1}} component="fieldset" variant="standard">
                        <FormControlLabel
                            label={t('calendar.evaluation')}
                            control={<Checkbox checked={globalState.showAppointments} onChange={(event) => {
                                setGlobalState({
                                    ...globalState,
                                    showAppointments: event.target.checked,
                                    refreshList: true
                                })
                            }}/>}
                        />
                    </FormControl>

                    <FormControl sx={{ml: 1}} component="fieldset" variant="standard"><FormControlLabel
                        label={t('calendar.bookings')}
                        control={<Checkbox checked={globalState.showBookings} onChange={(event) => {
                            setGlobalState({...globalState, showBookings: event.target.checked, refreshList: true})
                        }}/>}
                    /></FormControl>

                    {/*<FormControl sx={{ml: 1}} component="fieldset" variant="standard"><FormControlLabel
                        label={t('calendar.availability')}
                        control={<Checkbox checked={globalState.showAvailabilities} onChange={(event) => {
                            setGlobalState({
                                ...globalState,
                                showAvailabilities: event.target.checked,
                                refreshList: true
                            })
                        }}/>}
                    /></FormControl>*/}


                    <FormControl sx={{ml: 1}} component="fieldset" variant="standard">
                        <FormControlLabel
                            label={t("common.availableAppointment")}
                            control={
                                <Checkbox
                                    checked={globalState.showAppointmentAvailabilities}
                                    onChange={(event) => {
                                        setGlobalState({...globalState, showAppointmentAvailabilities: event.target.checked, refreshList: true
                                        })
                                    }}
                                />
                            }
                        />
                    </FormControl>

                    <FormControl sx={{ml: 1}} component="fieldset" variant="standard">
                        <FormControlLabel
                            label={t("common.availableCourse")}
                            control={
                                <Checkbox
                                    checked={globalState.showCourseAvailabilities}
                                    onChange={(event) => {
                                        setGlobalState({...globalState, showCourseAvailabilities: event.target.checked, refreshList: true
                                        })
                                    }}
                                />
                            }
                        />
                    </FormControl>

                </Div>
            </div>
        </Div>
    );
};

export default Header;
