import {createContext, useContext, useState} from 'react';

const CalendarContext = createContext({});

export const useCalendarContext = () => {
    return useContext(CalendarContext);
}

export const CalendarProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({
        addEditBooking: false,
        addEditAppointment: false,
        add: false,
        showBookings : true,
        showAppointments : true,
        refreshList : false,
        //showAvailabilities : false,
        showCourseAvailabilities : false,
        showAppointmentAvailabilities : false,
        search : ''
    });

    return <CalendarContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </CalendarContext.Provider>
}
